import type { MetaInfo } from 'vue-meta';

export const getMetaInfo = (page: any, locale: any = null, baseUrl: any = null, isNoIndex: boolean = false): MetaInfo => {
  if (!page) {
    return null;
  }

  const seoTags: MetaInfo = {
    meta: [],
  };

  if (page?.meta_title || page?.title || page?.name) {
    seoTags.title = page?.meta_title || page?.title || page?.name;
    seoTags.meta.push({
      hid: 'og:title',
      name: 'og:title',
      content: page?.meta_title,
    });
  }
  if (page?.meta_description) {
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: page.meta_description,
    });
    seoTags.meta.push({
      hid: 'og:description',
      name: 'og:description',
      content: page.meta_description,
    });
  }
  if (page?.meta_keyword || page?.meta_keywords) {
    seoTags.meta.push({
      hid: 'keywords',
      name: 'keywords',
      content: page?.meta_keyword || page?.meta_keywords,
    });
  }
  if (true) {
    seoTags.meta.push({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  } else {
    seoTags.meta.push({
      name: 'robots',
      content: 'index, follow',
    });
  }
  if (page?.thumbnail?.url?.length > 0) {
    seoTags.meta.push({
      hid: 'og:image',
      name: 'og:image',
      content: page.thumbnail.url,
    });
  }
  if (page?.image?.length > 0) {
    seoTags.meta.push({
      hid: 'og:image',
      name: 'og:image',
      content: page.image,
    });
  }
  if (page?.url_key?.length > 0) {
    seoTags.meta.push({
      hid: 'og:url',
      name: 'og:url',
      content: baseUrl + `/${locale}/` + page.url_key + '.html',
    });
  } else {
    seoTags.meta.push({
      hid: 'og:url',
      name: 'og:url',
      content: baseUrl + `/${locale}/`,
    });
  }
  if (page?.description?.length > 0) {
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: page.description,
    });
  }

  if (page?.og_description?.length > 0) {
    seoTags.meta.push({
      hid: 'og:description',
      name: 'og:description',
      content: page.og_description,
    });
  }

  if (page?.og_title?.length > 0 ) {
    seoTags.meta.push({
      hid: 'og:title',
      name: 'og:title',
      content: page?.og_title,
    });
  }

  if (page?.image?.length > 0) {
    seoTags.meta.push({
      hid: 'og:image',
      name: 'og:image',
      content: page.image,
    });
  }

  if (page?.og_image?.length > 0) {
    seoTags.meta.push({
      hid: 'og:image',
      name: 'og:image',
      content: page.og_image,
    });
  }

  if (page?.twitter_description?.length > 0) {
    seoTags.meta.push({
      hid: 'twitter:description',
      name: 'twitter:description',
      content: page.twitter_description,
    });
  }

  if (page?.twitter_title?.length > 0 ) {
    seoTags.meta.push({
      hid: 'twitter:title',
      name: 'twitter:title',
      content: page?.twitter_title,
    });
  }

  if (page?.twitter_image?.length > 0) {
    seoTags.meta.push({
      hid: 'twitter:image',
      name: 'twitter:image',
      content: page.twitter_image,
    });
  }



  return seoTags;
};
